import { defineMessages } from "react-intl";

export default defineMessages({
  btnDownloadReport: {
    id: "Appointment.AppointmentPreviewReportsCard.btnFormScreening",
    defaultMessage: "Download report",
  },
  btnRequestImages: {
    id: "Appointment.AppointmentPreviewReportsCard.btnRequestImages",
    defaultMessage: "Get images",
  },
  requestSuccessMessage: {
    id: "Appointment.AppointmentPreviewReportsCard.requestSuccessMessage",
    defaultMessage: "Request Successful",
  },
  requestSuccessDescription: {
    id: "Appointment.AppointmentPreviewReportsCard.requestSuccessDescription",
    defaultMessage:
      "A link where you can view the images has been sent to your email.",
  },
  requestErrorMessage: {
    id: "Appointment.AppointmentPreviewReportsCard.requestErrorMessage",
    defaultMessage: "Request Unsuccessful",
  },
  requestErrorDescription: {
    id: "Appointment.AppointmentPreviewReportsCard.requestErrorDescription",
    defaultMessage:
      "Please contact the center where you received your scan for assistance.",
  },
});
