import { defineMessages } from "react-intl";

export default defineMessages({
  titleInformationRequired: {
    id: "Appointment.AppointmentListContainer.titleInformationRequired",
    defaultMessage: "Information Required",
  },
  descriptionInformationRequired: {
    id: "Appointment.AppointmentListContainer.descriptionInformationRequired",
    defaultMessage:
      "We need some information from you before your MRI scan.\n\nEach of the forms below needs to be completed.",
  },
  btnEditPatient: {
    id: "Appointment.AppointmentListContainer.btnEditPatient",
    defaultMessage: "Edit patient information",
  },
  btnPatientInformation: {
    id: "Appointment.AppointmentListContainer.btnPatientInformation",
    defaultMessage: "Patient information",
  },
  btnFormPrivacy: {
    id: "Appointment.AppointmentListContainer.btnFormPrivacy",
    defaultMessage: "Privacy form",
  },
  btnFormConsent: {
    id: "Appointment.AppointmentListContainer.btnFormConsent",
    defaultMessage: "Consent form",
  },
  btnFormScreening: {
    id: "Appointment.AppointmentListContainer.btnFormScreening",
    defaultMessage: "Screening form",
  },
  btnDownloadReport: {
    id: "Appointment.AppointmentListContainer.btnFormScreening",
    defaultMessage: "Download report",
  },
  btnRequestImages: {
    id: "Appointment.AppointmentListContainer.btnRequestImages",
    defaultMessage: "Get images",
  },
});
